const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_QXkqgwCpu",
    "aws_user_pools_web_client_id": "tnlq8ak8ud5gnd72987aa289q",
    "oauth": {
        "domain": "baymanageridp.fullswingapps.com",
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://stgbaymanager.fullswingapps.com/login/",
        "redirectSignOut": "https://stgbaymanager.fullswingapps.com/login",
        "responseType": "code"
    }
};
 
export default awsmobile;