import { DefaultPrivacyLevel } from '@datadog/browser-rum';

enum Site {
  US1 = 'datadoghq.com',
}
type ConsoleApiName = 'log' | 'debug' | 'info' | 'warn' | 'error';

export const environment = {
  production: true,
  wsEndpoint: "wss://1hr1ljk1o1.execute-api.us-west-2.amazonaws.com/staging",
  awsAPIURL: 'https://k0zmbfs553.execute-api.us-west-2.amazonaws.com/staging/bay-manager-explorer',
  apiServer: 'https://stglauncher.fullswingapps.com/api/fs',
  hostName: 'stgbaymanager.fullswingapps.com',
  appConfigPath: './assets/config/app-config.json',
  defaultPage: 'event',

  //Local strorage values
  navName: '_fs_nav_name',
  bayManagerUser: 'Bay-Manager-User',
  calendarState: '_fs_fc_state',

  langId: '_fs_lang_id',
  defaultLanguage: 'en',
  defaultFontFamily: 'DavisSans',

  breakPointScreen: {
    small: 576,
    Medium: 768,
    large: 992,
    extraLarge: 1200,
    xExtraLarge: 1400,
  },

  // datadog
  datadog: {
    enableDatadogLogs: false,
    enableDatadogRum: false,
    rumConfig: {
      applicationId: '555b97cb-3bc9-4219-bd0d-96d418904d78',
      clientToken: 'pub89f826292a480deb8b685e6a42e38a3c',
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: Site.US1,
      service: 'bay-manager-website',
      env: 'staging',
      // Specify a version number to identify the deployed version of your application in Datadog
      version: '1.1.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      // currently tracing not allowed in dev because server needs to be configured
      allowedTracingUrls: ["https://stglauncher.fullswingapps.com"]
    },
    // make the forwardConsoleLogs only to "warn" using the ConsoleApiName[] required

    logsConfig: {
      clientToken: 'pub89f826292a480deb8b685e6a42e38a3c',
      site: Site.US1,
      service: 'bay-manager-website',
      env: 'staging',
      version: '1.1.0',
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['warn', 'error'] as ConsoleApiName[],
      sessionSampleRate: 100,
    },
  },
};
