import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, ValidationStatus } from './services/AuthService';
import { environment } from 'src/environments/environment';
import { datadogRum } from '@datadog/browser-rum';

/**
 * Prevent access to routes if access-token is not present.
 *
 * @export
 * @class AuthGuard
 * @implements {CanActivate}
 */
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _router: Router, private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // return this.authService
    //   .isTokenValid()
    //   .then(async (res: boolean) => {
    //     if (res) {
    //       this.authService.setValidationResult = ValidationStatus.Valid;

    //       if (state.url.split('/')[1]?.toLocaleLowerCase() === 'staff') {
    //         const user = await intialValueInterval(() => {
    //           return this.restApi.currentUser.getValue();
    //         });

    //         if (user.isSuperUser) {
    //           return true;
    //         } else {
    //           this._router.navigate(['event']);

    //           return false;
    //         }
    //       }

    //       return true;
    //     } else {
    //       this._router.navigate(['login']).then(() => {
    //         this.authService.setValidationResult = ValidationStatus.Invalid;
    //       });
    //       return false;
    //     }
    //   })
    //   .catch(() => {
    //     this._router.navigate(['login']).then(() => {
    //       this.authService.setValidationResult = ValidationStatus.Invalid;
    //     });
    //     return false;
    //   });

    return this.authService.isTokenValid().then((validation: boolean) => {
      // if (res) {
      //   this.authService.validationResult = ValidationStatus.Valid;

      //   if (state.url.split('/')[1]?.toLocaleLowerCase() === 'staff') {
      //     const user = await intialValueInterval(() => {
      //       return this.restApi.user;
      //     });

      //     if (user.isSuperUser) {
      //       return true;
      //     } else {
      //       this._router.navigate(['event']);

      //       return false;
      //     }
      //   }

      //   return true;
      // } else {
      //   this._router.navigate(['login']).then(() => {
      //     this.authService.validationResult = ValidationStatus.Invalid;
      //   });
      //   return false;
      // }

      if (validation) {
        //this.authService.isAuthenticate = true;
        if (environment.datadog.enableDatadogRum) {
          if (datadogRum.getUser()['id'] === undefined) {
            return this.authService
              .getUserInfo()
              .then((user) => {
                datadogRum.setUser({
                  id: user.attributes['sub'],
                  name: user.attributes['name'],
                  email: user.attributes['email'],
                });
                return true;
              })
              .catch((error) => {
                console.error('error', error);
                // even if it fails, we still want to return true since validation passed
                return true;
              });
          }
        }
        return true;
      } else {
        this._router.navigate(['login']).then(() => {
          this.authService.validationResult = ValidationStatus.Invalid;
          if (environment.datadog.enableDatadogRum) {
            datadogRum.clearUser();
          }
        });
        return false;
      }
    });
  }
}
